<template>
  <div id="solutions_media">
    <solutions-header
      class="media-services-header"
      :title="$t('solutions.mediaAndEntertainment.title')"
      :subtitle="$t('solutions.mediaAndEntertainment.subtitle')"
    />

    <solutions-content
      :problem-statement="$t('solutions.mediaAndEntertainment.problem.statement')"
      :problem-description="$t('solutions.mediaAndEntertainment.problem.description')"
      :solution="$t('solutions.mediaAndEntertainment.solution')"
      :pipeline="require('@/assets/images/icons/solutions/media/pipeline.svg')"
      :section-img="require('@/assets/images/icons/solutions/media/statement.svg')"
    />
  </div>
</template>

<script>
import SolutionsHeader from '@/views/pages/Solutions/components/SolutionsHeader/Index.vue';
import SolutionsContent from '@/views/pages/Solutions/components/SolutionsContent/Index.vue';

export default {
  name: 'Media',
  components: {
    SolutionsHeader,
    SolutionsContent,
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep {
    .media-services-header {
      .content {
        background: url('~@/assets/images/background/solutions-banner-media.svg') 90% bottom no-repeat;
      }
    }
  }
</style>
